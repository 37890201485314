<template>
  <ion-page>
    <div class="payment-status-content">
      <div class="img">
        <ion-img class="onboardingImg mx-auto" src="/assets/images/payment-updated.svg" />
      </div>
      <ion-row class="align-center" id="row">
        <ion-col class="headline" size="12">
          <ion-label>{{ $t('orderB2b.paymentStatusUpdated') }}</ion-label>
        </ion-col>
        <ion-col class="hint" size="12">
          <ion-label class="underline">{{ $t('orderB2b.paymentStatusUpdatedHint') }}</ion-label>
        </ion-col>
      </ion-row>
    </div>
  </ion-page>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentTransactionCompleted'
});
</script>
<style lang="scss" scoped>
.img {
  margin: 23vh auto 0;
  width: 70%;
}
.headline {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
}
.hint {
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  .underline {
    padding-bottom: 5px;
    border-bottom: 3px #ff9600 solid;
  }
}
</style>
